































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Site from "@/models/Site";
import SitesServices from "@/services/SitesServices";

import { Guid } from "guid-typescript";
import table from "@/utils/table";
import { UserRoleEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class SitesModal extends Vue {
  @Prop({ default: false }) public show!: boolean;
  @Prop({ default: undefined }) public props!: any;

  private sites: Array<Site> = [];
  private checkSites: Array<string> = [];

  public mounted() {
    SitesServices.getSitesByAdministrator(this.$i18n.locale).then(
      (response) => {
        this.sites = response.data;
      }
    );
  }

  @Watch("show")
  onShowChange(value: boolean, oldValue: boolean) {
    if (value == false) this.checkSites = [];
    else {
      table
        .findByPath(this.props.rowData, this.props.rowField.property)
        .forEach((site: any) => {
          if (
            this.sites.find((siteName: any) => {
              return siteName.site.id == site.site.id;
            })
          ) {
            this.checkSites.push(site.site.id);
          }
        });
    }
  }

  @Watch("checkSites")
  async onCheckSitesChange(value: Array<any>, oldValue: Array<any>) {
    if (
      value.length > 1 &&
      !value.every(
        (st: any) =>
          this.getSiteById(st).site.country.id ==
          this.getSiteById(value[0]).site.country.id
      )
    ) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("cantSelect2SitesWithDifferentCountry").toString(),
        text: this.$t("cantSelect2SitesWithDifferentCountry").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      this.$nextTick(() => this.checkSites.splice(-1, 1));
    }
  }

  public getSiteById(id: string): any {
    return this.sites.find((st: any) => st.site.id == id);
  }

  public validate() {
    table.setByPath(
      this.props.rowData,
      this.props.rowField.property,
      this.checkSites.map((guid) => {
        const temp: any = this.sites.find((site: any) => site.site.id == guid);
        if (temp !== undefined) return temp;
      })
    );
    this.$emit("validate", this.props.rowData);
  }
}
